<template>
  <div>
    <WaBtn />
    <!-- main banner -->
    <MainBanner />

    <!-- benefit -->
    <div class="relative py-10 lg:max-w-5xl lg:mx-auto">
      <div
        class="container absolute z-0 w-full h-full md:mx-auto md:left-0 md:right-0 -top-20"
      >
        <h1
          class="py-2 text-lg font-semibold text-center text-white uppercase bg-gray-1"
        >
          Benefits of Fully Refurbished Hybrid Battery Technology
        </h1>
        <div class="md:flex md:flex-wrap">
          <div v-for="(item, i) in info" :key="i" class="md:w-1/2 lg:w-1/4">
            <div
              :class="item.bgColor"
              class="flex items-center py-3 md:justify-center md:px-2"
            >
              <div class="w-1/3">
                <img :src="item.image" alt="" class="w-16 h-auto mx-auto" />
              </div>
              <div class="w-2/3 text-white md:pl-1">
                <p class="font-bold text-white capitalize text-basemd:text-sm">
                  {{ item.p }}
                </p>
                <!-- <p>{{ item.p2 }}</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- bg height -->
    <div class="h-64 md:h-16 lg:h-5"></div>

    <!-- about us -->
    <div class="py-10">
      <div class="container md:flex md:items-start">
        <div class="md:w-1/2">
          <div class="flex items-center">
            <div class="bg-green-1 my-3 h-0.5 w-6"></div>
            <h1 class="pl-3 text-base font-bold text-green-1">About Us</h1>
          </div>
          <p class="text-2xl font-semibold capitalize text-gray-1">
            Helps in conservation
          </p>
          <div class="pt-4">
            <div class="space-y-2 text-sm text-justify text-gray-600">
              <p>
                With years of proficiency in Industrial Battery Regeneration
                Technology, our company Nusa Tenaga Uteknologi, Malaysia
                alongside the skilled collaboration with our principal Renergy,
                Korea have advanced our expertise and knowledge in refurbishing
                Automotive Hybrid batteries for Japanese and European Hybrid
                vehicles available nationwide.
              </p>
              <p>
                Our technology and technique meticulously follow our principal’s
                Patent procedure which is registered with the Korean
                Intellectual Property Office (Patent No:10-2264429). This
                includes the conventional Nimh battery pack and our newly
                researched and developed modified Li-Ion battery pack.
              </p>
              <p>
                Our direct recovery system is a step higher than a fully
                recycled battery pack as we prefer to reuse the same components
                to optimize the outcome while saving the environment. Thus, we
                are able to extend the warranty to even 3 years which is rare
                for such product in the market but we make it possible at
                nusatenaga. In effect, this is a sustainability effort!
              </p>
              <p>
                Besides, nusatenaga has opted to diversify its portfolio to
                retail Lithium-Ion batteries for low-powered vehicles, mainly
                material handling equipment's.
              </p>
            </div>
          </div>
        </div>

        <div class="md:w-1/2">
          <div class="flex items-center justify-center w-full py-3">
            <img
              src="/images/go-green.jpg"
              alt=""
              class="w-20 mr-4 md:w-28 lg:w-52"
            />
            <h2 class="font-bold text-gray-800">MyHS00027/22</h2>
          </div>
          <img src="/images/about-us.jpg" alt="" />
        </div>
      </div>
    </div>

    <!-- promotion -->
    <!-- <div
      class="py-10 bg-center bg-no-repeat bg-cover bg-promo-adjust"
      style="background-image: url('/images/bg-promo.jpg')"
    >
      <div class="container md:py-10">
        <div class="md:w-3/4">
          <h2
            class="text-2xl font-bold leading-tight text-center text-white text-shadow md:text-4xl md:text-left"
          >
            Hari Raya Balik Kampung
            <br />
            <span class="text-red-500 uppercase">BIG Promotion</span>
          </h2>
          <p
            class="pt-3 text-lg font-semibold leading-tight text-center text-white md:text-left"
          >
          For Toyota & Lexus (Selected Car Model)* 
          </p>
          <div
            class="mt-8 space-y-2 text-sm leading-tight text-center text-white md:text-left"
          >
            <p
              class="text-base font-medium leading-tight lg:text-lg lg:leading-tight"
            >
              Discount up to RM400.00 upon installation. Hurry up, it’s so easy,
              <a href="https://wa.me/60193501459" class="font-bold text-red-500"
                >WhatsApp (019-3501459)</a> or <a
                href="#"
                class="font-bold text-red-500"
                v-scroll-to="{ el: '#element', duration: 2500 }"
                >Enquire Us Now </a> to Get the Special Deal~
            </p>
            <p class="text-base">*Terms & Condition Apply</p>
            <p class="pb-2 text-base font-medium">
              Effective Date: 18 March 2023 to 30 April 2023
            </p>
          </div>
         
        </div>
      </div>
    </div> -->

    <!-- service title -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <div class="flex items-center">
          <div class="bg-green-1 my-3 h-0.5 w-6"></div>
          <h1 class="pl-3 text-2xl font-bold text-green-1">Our Product</h1>
        </div>
        <p class="text-2xl font-semibold capitalize text-gray-1">
          Fully Refurbished Hybrid Batteries
        </p>
        <p class="text-base font-medium text-gray-600">
          (NiMH and Upgraded Lithium Ion Pack)
        </p>
      </div>
    </div>
    <!-- battery  -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/battery.jpg')"
    >
      <div class="container">
        <div class="">
          <img src="/images/logo-product.png" alt="" class="" />
        </div>
      </div>
    </div>
    <!-- service  -->
    <div
      class="pt-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-service.jpg')"
    >
      <div class="container">
        <div class="">
          <h1
            class="pb-4 text-lg font-semibold text-center text-white lg:text-4xl md:text-2xl"
          >
            Refurbishing Process
          </h1>
          <div class="flex flex-wrap pt-5">
            <div
              v-for="(item, i) in service"
              :key="i"
              class="w-1/2 px-1 pb-3 md:w-1/3 lg:w-1/4 lg:px-3 lg:pb-5"
            >
              <img :src="item.image" :alt="item.alt" class="rounded-lg" />
              <div
                class="pt-3 text-xs leading-tight text-center text-white md:text-sm lg:font-semibold"
              >
                <p>{{ item.alt }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="hidden lg:block lg:pt-4 xl:pt-10">
          <img src="/images/product-1.png" alt="" class="xl:w-3/4 xl:mx-auto" />
        </div> -->
      </div>
    </div>
    <div>
      <div class="border md:flex md:flex-wrap border-gray-1">
        <div class="w-full">
          <div
            class="py-5 text-xl font-bold text-center text-white border-b md:border-b-0 md:border-r bg-green-1 border-gray-1"
          >
            <p>
              <i class="pr-2 text-2xl fa-solid fa-check"></i>50% COST SAVING
            </p>
          </div>
        </div>
        <!-- <div class="md:w-1/3">
          <div
            class="py-5 text-sm font-bold text-center text-white border-b md:border-b-0 md:border-r bg-green-1 border-gray-1"
          >
            <p>
              <i class="pr-2 text-2xl fa-solid fa-check"></i>50% EXTENDED
              WARRANTY
            </p>
          </div>
        </div> -->
        <!-- <div class="md:w-1/3">
          <div class="py-5 text-sm font-bold text-center text-white bg-green-1">
            <p>
              <i class="pr-2 text-2xl fa-solid fa-check"></i>50% ECO-FRIENDLY
            </p>
          </div>
        </div> -->
      </div>
    </div>
    <div class="pt-8">
      <div class="container">
        <div class="">
          <p
            class="py-2 mx-auto font-semibold text-center text-white transition duration-200 rounded-lg hover:bg-green-1 w-52 bg-gray-1"
          >
            <a href="tel:+60193501459">Call Us Now</a>
          </p>
        </div>
      </div>
    </div>

    <!-- advantages -->
    <div class="py-10">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-gray-1">
          ADVANTAGES
        </h1>
        <div class="pt-8 lg:flex lg:items-center">
          <div class="lg:w-1/2">
            <div
              v-for="(item, i) in advantage"
              :key="i"
              class="flex pb-4 md:items-center lg:items-start"
            >
              <div class="w-1/3 lg:order-2">
                <div
                  class="p-5 bg-white rounded-full shadow-lg md:w-32 md:h-32 lg:w-28 lg:h-28 md:mx-auto"
                >
                  <img :src="item.image" :alt="item.alt" class="" />
                </div>
              </div>
              <div class="w-2/3 pl-3 lg:text-right lg:pr-3 lg:pl-0">
                <h1 :class="item.textColor" class="pb-2 text-lg font-bold">
                  {{ item.alt }}
                </h1>
                <p class="pb-3 text-xs text-gray-600 border-b border-gray-300">
                  {{ item.p }}
                </p>
              </div>
            </div>
          </div>
          <div class="pb-4 lg:w-1/2 lg:pb-0 lg:px-1">
            <img
              src="/images/advantages.jpg"
              alt=""
              class="rounded-lg shadow md:w-2/3 lg:w-full md:mx-auto"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-choose.jpg')"
    >
      <div class="container">
        <h1 class="text-2xl font-bold text-center text-white">Why Choose Us</h1>
        <div class="bg-green-1 w-10 h-0.5 mx-auto my-3"></div>

        <div class="flex flex-wrap pt-6 xl:max-w-4xl xl:mx-auto">
          <div
            v-for="(item, i) in choose_us"
            :key="i"
            class="w-1/2 px-1 pb-3 md:w-1/3"
          >
            <div class="p-5 mx-auto bg-white rounded-full w-28 h-28">
              <img :src="item.image" :alt="item.alt" class="mx-auto" />
            </div>
            <div class="pt-3 text-center text-white">
              <p class="text-base font-semibold leading-tight">
                {{ item.alt }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- faq -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-faq.jpg')"
    >
      <div class="container xl:py-14">
        <div class="md:w-2/3 xl:w-1/2">
          <div class="flex items-center">
            <div class="bg-gray-1 my-3 h-0.5 w-6"></div>
            <h1 class="pl-3 text-base font-bold text-gray-1">FAQ</h1>
          </div>
          <p class="text-2xl font-semibold capitalize text-gray-1">
            Nusa Tenaga UTeknologi Sdn Bhd
          </p>
          <div class="">
            <Accordion :faq="faq" :focus="false" class="pt-4" />
            <div class="pt-6">
              <p
                class="py-2 font-semibold text-center text-white transition duration-200 ease-in-out transform rounded-m d bg-gray-1 hover:text-white hover:bg-green-1 hover:scale-105 w-44"
              >
                <a href="tel:+60193501459">Call Us Now</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- testimoni&enquiry form  -->
    <div class="py-10">
      <div class="container lg:flex lg:items-center">
        <div class="lg:w-1/2">
          <div class="flex items-center">
            <div class="bg-green-1 my-3 h-0.5 w-6"></div>
            <h1 class="pl-3 text-base font-bold text-green-1">Testimonials</h1>
          </div>
          <p class="text-2xl font-semibold capitalize text-gray-1">
            What Our Customers Say?
          </p>
          <div class="pt-6">
            <carousel
              :autoplay="true"
              :loop="true"
              :responsive="{
                0: { items: 1, nav: false },
              }"
            >
              <!--carousel area-->
              <div v-for="(item, i) in testimonial" :key="i" class="md:px-2">
                <div class="px-1 py-6">
                  <p
                    v-html="item.p"
                    class="flex items-center justify-center text-sm leading-tight text-center text-gray-600"
                  >
                    {{ item.p }}
                  </p>
                </div>
                <div class="bg-green-1 h-8 w-0.5 mx-auto"></div>
                <h1
                  class="pt-4 text-2xl font-semibold text-center text-green-1"
                >
                  {{ item.client }}
                </h1>
              </div>
            </carousel>
          </div>
        </div>

        <div id="element" class="pt-5 lg:w-1/2 lg:pt-0">
          <div class="px-2 py-4 rounded-lg shadow-lg bg-green-1">
            <h1 class="pb-2 text-2xl font-bold text-center text-white">
              Send An Enquiry
            </h1>
            <p class="pb-4 text-sm font-semibold text-center text-white">
              Fill out the form below and we'll get back to you as soon as
              possible
            </p>
            <!-- feedback.activamedia.com.sg script begins here -->
            <iframe
              allowtransparency="true"
              style="height: 540px; overflow: auto"
              width="100%"
              id="contactform123"
              name="contactform123"
              marginwidth="0"
              marginheight="0"
              frameborder="0"
              src="//feedback.activamedia.com.sg/my-contact-form-5585114.html"
            ></iframe>
            <!-- feedback.activamedia.com.sg script ends here -->
          </div>
        </div>
      </div>
    </div>

    <!-- footer  -->
    <div class="py-10 bg-green-1">
      <div class="container">
        <div class="lg:flex lg:items-center">
          <div class="lg:w-1/2">
            <img
              src="/images/main-logo.png"
              alt=""
              class="mx-auto w-60 lg:w-2/3"
            />
            <div
              class="pt-3 space-y-1 text-lg text-center text-gray-700 lg:text-left lg:pt-8"
            >
              <p>
                <span class="text-lg font-semibold">Office:</span><br />Nusa
                Tenaga UTeknologi Sdn. Bhd. (1066261U)
              </p>
              <p class="leading-tight">
                No. 30, 1A, Block 2, Jalan PahatG 15/G, 40200 Shah Alam,
                Selangor, Malaysia
              </p>
              <div>
                <p>
                  Tel: <a href="tel:+60355451953">+603 5545 1953</a>,
                  <a href="tel:+60193501459">+6019-350 1459</a>
                </p>
              </div>
            </div>

            <div
              class="pt-3 space-y-1 text-lg text-center text-gray-700 lg:text-left"
            >
              <p>
                <span class="font-semibold">Hybrid Center:</span><br />Nusa
                Tenaga UTeknologi Sdn. Bhd. (GR Power Engineering)
              </p>
              <p class="leading-tight">
                12, Jalan PenyelenggaraU1/77, Hicom GlenmarieIndustrial Park,
                SeksyenU1, 40150, Shah Alam, Selangor, Malaysia.
              </p>
              <div>
                <p class="">
                  <a href="https://goo.gl/maps/C1bfoMbgXD3TfsRt9"
                    >Get Directions</a
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="pt-6 lg:w-1/2 lg:pt-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.0494986245585!2d101.55397671502308!3d3.0814630977560737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4de2bcb3ad5f%3A0x464a7f761e7debd5!2sNusa%20Tenaga%20UTeknologi%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1664780799987!5m2!1sen!2smy"
              width="100%"
              height="300"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <!-- Copyright -->
    <div class="py-3 bg-gray-1">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Accordion from "@/components/Accordion.vue";
import carousel from "vue-owl-carousel";
import WaBtn from "@/components/WaBtn.vue";
import MainBanner from "@/components/MainBanner.vue";

export default {
  name: "Home",
  components: {
    Accordion,
    carousel,
    WaBtn,
    MainBanner,
  },
  data() {
    return {
      info: [
        {
          bgColor: "bg-green-1",
          image: "/images/icon-1.png",
          p: "50% cost saving!",
        },
        {
          bgColor: "bg-green-2",
          image: "/images/icon-2.png",
          p: "Fully refurbished!",
        },
        {
          bgColor: "bg-green-3",
          image: "/images/icon-3.png",
          p: "More Eco Friendly!",
        },
        {
          bgColor: "bg-green-4",
          image: "/images/icon-4.png",
          p: "With Extended Warranty!",
        },
      ],
      service: [
        {
          image: "/images/service-12.jpg",
          alt: "1. SAFETY FIRST",
        },
        {
          image: "/images/service-2.jpg",
          alt: "2. DISMANTLING BATTERY ASSEMBLY",
        },
        {
          image: "/images/service-3.jpg",
          alt: "3. DISMANTLING FAN AND ELECTRONIC ACCESSORIES ",
        },
        {
          image: "/images/service-1.jpg",
          alt: "4. DISMANTLING WIRING HARNESS FROM BATTERY PACK",
        },
        {
          image: "/images/service-4.jpg",
          alt: "5. DISMANTLING INTER-MODULE CONNECTORS AND FRAME",
        },
        {
          image: "/images/service-5.jpg",
          alt: "6. COMPLETELY DISMANTLED HYBRID BATTERY ASSEMBLY",
        },
        {
          image: "/images/service-6.jpg",
          alt: "7. DISCHARGING MODULE 1-10 (COMPACT MODEL)",
        },
        {
          image: "/images/service-7.jpg",
          alt: "8. DISCHARING MODULE 11-20 (COMPACT MODEL)",
        },
        {
          image: "/images/service-8.jpg",
          alt: "9. CHARGING PROCESS",
        },
        {
          image: "/images/service-9.jpg",
          alt: "10. ASSEMBLY OF BATTERY SAFETY SYSTEM WIRING",
        },
        {
          image: "/images/service-10.jpg",
          alt: "11. ASSEMBLY OF FAN, ACCESSORIES AND FRAME TO COMPLETE",
        },
        {
          image: "/images/service-11.jpg",
          alt: "12. ON VEHICLE FITTING AND TESTING",
        },
      ],
      advantage: [
        {
          textColor: "text-blue-1",
          image: "/images/advan-1.png",
          alt: "Cost Effective",
          p: "Hybridsave is a trusted cost-effective formula to replace your ailing hybrid battery. It increases the competitiveness of hybrid vehicles in the automotive industry, promoting affordability.",
        },
        {
          textColor: "text-turq-1",
          image: "/images/advan-2.png",
          alt: "Eco Friendly",
          p: "Hybridsave is an eco-friendly product. Whether using NiMH or Lithium-Ion modules, it can be completely reused preventing environmental damage due to improper disposal. ",
        },
        {
          textColor: "text-purple-1",
          image: "/images/advan-3.png",
          alt: "Extended Warranty",
          p: "Hybridsave is packaged with an express warranty of 1 year. For consumers who prefer an everlasting sans souci drive, nusatenaga offers the extended warranty program for 3 years which is uncommon but well grounded (T&C Applies).",
        },
        {
          textColor: "text-blue-2",
          image: "/images/advan-4.png",
          alt: "Genuine Product",
          p: "Hybridsave is 100% guaranteed to be genuine. At nusatenaga, we only use original Japanese/European modules to optimize the performance of the vehicle. We offer 1-1 Exchange Program where the current battery pack is removed and replaced with a fully refurbished original battery pack at an average of 95% capacity. ",
        },
      ],
      choose_us: [
        { image: "/images/choose-1.png", alt: "Quality Assurance" },
        { image: "/images/choose-2.png", alt: "99% Customer Satisfactions" },
        { image: "/images/choose-3.png", alt: "Warranty Provided" },
        { image: "/images/choose-4.png", alt: "Experienced & Professional" },
        { image: "/images/choose-5.png", alt: "High Quality Hybrid Battery" },
        { image: "/images/choose-6.png", alt: "Save Money, Save Earth" },
      ],
      faq: [
        {
          q: "Why do hybrid batteries fail so fast?",
          a: "It’s due to the high temperature and humidity resulting in corrosion which weakens the battery.",
        },
        {
          q: "How much do I have to spend for a refurbished battery?",
          a: "Just about a fraction of the price of a new battery with trade in.",
        },
        {
          q: "What is the assurance for performances for this refurbished battery?",
          a: "Every battery goes through strict performance load test before and after regeneration of the batteries and also equalized to meet required performance. Our warranty programme provides that assurance.",
        },
      ],
      testimonial: [
        {
          p: "Get to know Nusa Tenaga through one of my friends who work as Car Foreman. Saved quite a fraction of cost of new battery with warranty too, will definitely recommend my friend who need to change hybrid battery in the near future. Thanks for your knowledgeable and professional advice!!",
          client: "Kumaran",
        },
        {
          p: "Awesome experience! I could not be more pleased! I am so happy with my decision to use refurbished hybrid battery, some more with 1-year warranty. I highly recommend this company!",
          client: "Ivan Chong",
        },
        {
          p: "Mr SJ is a very professional and friendly boss, he is open, honor and thorough, he works efficiently and done it on time. My husband enjoys conversating with him while he works and they test drive the vehicle to ensure it was fixed, I would definitely recommend him and this company to anyone I know.",
          client: "Shima",
        },
        {
          p: "Mr Suppiah is recommended by my friend, he communicated efficiently before I arrive and was very polite and worked quickly. Battery was installed easily and the warning lights immediately turn off on my car. We went for a test drive and the car had no more lag during acceleration and drove great. He followed up to make sure everything went smoothly. Highly recommend. Thank you!",
          client: "Fairuz",
        },
        {
          p: "My Hybrid Battery went out in my lovely car. The first guy rebuilds my battery for few hundred ringgit and would not give me a warranty because he said there was no telling when another cell would fail. Well, 5 months later It happened again. I found Mr SJ who would exchange my hybrid battery and given me 1-year warranty. I have been using it more than a year and it still works well. I can say that I have complete confidence in this company, very professional and knowledgeable, he shows up on time, do the job right and he do what he says. I highly recommend that you save yourself the headaches and give him a call!",
          client: "Thinesh",
        },
        {
          p: "Nusa Tenaga answers all your questions and more. Simple, Easy, Fast and Professionals who help when you are in need of assistance. ",
          client: "Jordan Ho",
        },
      ],
    };
  },
};
</script>
<style>
.text-shadow {
  text-shadow: 2px 2px 4px #000;
}
</style>
