<template>
  <div
    class="bg-center bg-no-repeat bg-cover"
    style="background-image: url('/images/bg-main.jpg')"
  >
    <!-- main logo -->
    <div class="py-2">
      <div class="container">
        <div
          class="flex flex-wrap items-center justify-center lg:w-full md:space-x-1 md:items-center"
        >
          <div class="mr-1 greenCustom">
            <img src="/images/go-green-1.png" alt="" class="" />
          </div>
          <div class="logoCustom">
            <img src="/images/main-logo-white.png" alt="" class="pb-4" />
          </div>
        </div>
      </div>
    </div>
    <div class="container py-32 md:py-44 lg:pt-20">
      <div class="text-center text-white text-shadow">
        <h1 class="pb-2 text-3xl font-semibold lg:text-4xl text-green-1">
          Looking for a
        </h1>
        <h1 class="pb-2 text-4xl font-bold md:text-5xl lg:text-6xl">
          <!-- HYBRID BATTERY TECHNOLOGY -->
          Hybrid Battery For Your Car?
        </h1>
      </div>
      <div
        v-if="!hideEnquiry"
        class="flex pt-4 space-x-2 text-sm md:justify-center"
      >
        <p
          class="py-2 font-semibold text-center transition duration-200 ease-in-out transform bg-white rounded-md hover:text-white hover:bg-green-1 hover:scale-105 text-gray-1 w-44"
        >
          <a href="tel:+60193501459">Call Us Now</a>
        </p>
        <p
          class="py-2 font-semibold text-center transition duration-200 ease-in-out transform bg-white rounded-md hover:text-white hover:bg-green-1 hover:scale-105 text-gray-1 w-44"
        >
          <a
            href="https://wa.me/60193501459?text=I%20would%20like%20to%20get%20a%20hybrid%20battery%20price%20for%20(Car%20Model)."
            >Get A Free Quote</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.greenCustom img {
  width: 100px;
}
.logoCustom img {
  width: 200px;
}

@media (min-width: 768px) {
  .greenCustom img {
    width: 150px;
  }
  .logoCustom img {
    width: 400px;
  }
}
@media (min-width: 1024px) {
  .greenCustom img {
    width: 200px;
  }
  .logoCustom img {
    width: 600px;
  }
}
</style>
